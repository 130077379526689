import * as React from 'react'

import { DANGER_RATINGS_EXPLAINED_OPENED } from '@avalanche-canada/constants/products/mixpanel'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { VerticalGallery } from 'components/gallery/VerticalGallery'
import { Panel } from 'components/panel/Panel'
import Shim from 'components/Shim/Shim'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'
import { StructuredText } from 'prismic/components/base'
import { useLocale } from 'stores/SettingsStore'
import { useForecast } from './Context'
import { useForecastDocuments } from 'stores/PrismicDataStore'

import styles from './Forecast.module.css'

Footer.propTypes = {
    children: PropTypes.node,
}

export default function Footer({ children }) {
    return (
        <footer className={styles.Footer}>
            {children || (
                <React.Fragment>
                    <Disclaimer />
                </React.Fragment>
            )}
        </footer>
    )
}

export function Disclaimer({ uid = 'forecast-disclaimer', expanded }) {
    return <Prismic uid={uid} expanded={expanded} />
}

export function Prismic({ uid, expanded }) {
    const locale = useLocale()

    const forecastDocuments = useForecastDocuments()
    const { data } = forecastDocuments[locale].find(forecastDocument => forecastDocument.uid === uid)

    if (!data) return null

    return (
        <Panel header={data.title} expanded={expanded}>
            <Shim horizontal>
                <StructuredText value={data.body} />
            </Shim>
        </Panel>
    )
}
