import React from 'react'

import { AppLayout } from 'layouts/AppLayout'
import { useDataLoading } from 'hooks/data/useDataLoading'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { InstallationInstructions } from 'components/misc/InstallationInstructions'
import { isInstalled } from 'utils/pwa'
import { getAllPrismicData } from 'prismic/getPrismicData'

export async function getStaticProps() {
    return {
        props: {
            prismicData: await getAllPrismicData(),
        },
        // Revalidate every 24 hours
        revalidate: 60 * 60 * 24,
    }
}

export default function Index({ prismicData }) {
    const { isLoading } = useDataLoading()

    if (!isInstalled() && process.env.NODE_ENV !== 'development') {
        return <InstallationInstructions />
    }

    if (isLoading) {
        return <LoadingSpinner />
    }

    return <AppLayout prismicData={prismicData} />
}
