import React, { useState, useEffect } from 'react'

import { useRouter } from 'next/router'
import {
    ACCIDENT,
    FORECAST,
    MOUNTAIN_CONDITIONS_REPORT,
    MOUNTAIN_INFORMATION_NETWORK,
    SPAW,
    WEATHER_STATION,
    ICE_CLIMB,
} from '@avalanche-canada/constants/products'

import { ExplorerLayout } from 'layouts/ExplorerLayout'
import { Explorer } from 'components/map/Drawer/Explorer'
import { MINDrawerLayout } from 'layouts/MINDrawerLayout'
import { MINListView } from 'components/Routing/MIN/MINListView'
import { Forecast } from 'components/Routing/Forecast/Forecast'
import { MCRListView } from 'components/Routing/MCR/MCRListView'
import { WeatherStationListView } from 'components/Routing/WeatherStation/WeatherStationListView'
import { AccidentListView } from 'components/Routing/Accident/AccidentListView'
import { SPAW as SPAWComponent } from 'components/Routing/SPAW/SPAW'
import { Account } from 'components/Account/Account'
import {
    DEFAULT_LAYOUT,
    EXPLORER_LAYOUT,
    EXPLORER_VIEW,
    MIN_LAYOUT,
    DEFAULT_HISTORY_VALUE,
    DEFAULT_EXPLORER_VIEW,
} from 'constants/layout'
import { isBottomSheetMinimized, setBottomSheetToHalfHeight, setBottomSheetToMinHeight } from 'stores/BottomSheetStore'
import { ProductTour } from 'components/ProductTour/ProductTour'
import {
    useLayout,
    useSetLayout,
    useExplorerView,
    useSetExplorerView,
    useProductID,
    useSetProductID,
    useHistory,
    useSetHistory,
    useNextBackShouldCloseSheet,
    useSetNextBackShouldCloseSheet,
    useEditingMIN,
    useSetEditingMIN,
} from 'stores/ViewStore'
import { useMap, useSetMap, useActiveRegion, useSetActiveRegion } from 'stores/MapStore'
import { useForecasts } from 'hooks/data/forecasts'
import { UpdateOverlay } from 'components/UpdateOverlay/UpdateOverlay'
import { useUpdateAvailable } from 'stores/UpdateStore'
import { IceClimbListView } from 'components/Routing/IceClimb/IceClimbListView'
import { useSetSponsors, useSetHighlight, useSetForecastDocuments } from 'stores/PrismicDataStore'

export const AppLayout = ({ prismicData }) => {
    const router = useRouter()
    const updateAvailable = useUpdateAvailable()

    const map = useMap()
    const setMap = useSetMap()
    const activeRegion = useActiveRegion()
    const setActiveRegion = useSetActiveRegion()
    const { areas } = useForecasts()

    // State
    const layout = useLayout()
    const setLayout = useSetLayout()
    const explorerView = useExplorerView()
    const setExplorerView = useSetExplorerView()
    const productID = useProductID()
    const setProductID = useSetProductID()
    const history = useHistory()
    const setHistory = useSetHistory()
    const nextBackShouldCloseSheet = useNextBackShouldCloseSheet()
    const setNextBackShouldCloseSheet = useSetNextBackShouldCloseSheet()
    const editingMIN = useEditingMIN()
    const setEditingMIN = useSetEditingMIN()
    const [forceShowTour, setForceShowTour] = useState(false)
    const [historyPopped, setHistoryPopped] = useState(false)
    const setSponsors = useSetSponsors()
    const setHighlight = useSetHighlight()
    const setForecastDocuments = useSetForecastDocuments()

    const updateView = newView => {
        setExplorerView(newView)
        setProductID(null)
    }

    // Updates the view and product, for example tapping the map.
    // tapping the map means that the view isn't already set. Otherwise we could just use setProductID
    const updateProduct = (view, id) => {
        setExplorerView(view)
        setProductID(id)

        setBottomSheetToHalfHeight()
    }

    const handleSetLayout = (layout, fromTour = false) => {
        // Sets the view
        setLayout(layout)
        setExplorerView(EXPLORER_VIEW)

        // Clears the editing MIN in case it is still set from going to edit MIN from Account page
        setEditingMIN(null)

        // Clears the history so that back button exits the app
        setHistory([DEFAULT_HISTORY_VALUE])
        if (layout === EXPLORER_LAYOUT && !fromTour && router.query.canGoBack === 'true') {
            router.back()
        }
    }

    useEffect(() => {
        setSponsors(prismicData?.sponsors || [])
        setHighlight(prismicData?.highlight || [])
        setForecastDocuments(prismicData?.forecastDocuments || { en: [], fr: [] })
    }, [prismicData])

    useEffect(() => {
        // If we're on the home screen, we shouldn't do anything
        if (layout === DEFAULT_LAYOUT && explorerView === DEFAULT_EXPLORER_VIEW && productID === null) {
            setHistoryPopped(false)
            setHistory([DEFAULT_HISTORY_VALUE])
            return
        }

        // If we haven't already set canGoBack, we should do so
        if (router.query.canGoBack !== 'true') {
            addCanGoBack(router)
        }

        // We just hit the back button where we set the history already
        if (historyPopped) {
            setHistoryPopped(false)
            return
        }

        // Check: the current view is not the same as the last on the stack
        // This shouldn't happen but this is one last check
        if (lastViewSameAsCurrent(history, layout, explorerView, productID)) return

        // All other scenarios, we should add the current view to the history
        setHistory([...history, { layout, view: explorerView, id: productID }])
    }, [router, layout, explorerView, productID])

    // Sets up allowing the sheet to be shrunk on the next back button press
    useEffect(() => {
        if (router.query.canGoBack !== 'true' && !isBottomSheetMinimized()) {
            setNextBackShouldCloseSheet(true)
            addCanGoBack(router)
        }
    }, [router])

    // Removes an item from the history stack
    router.beforePopState(() => {
        if (nextBackShouldCloseSheet && history.length === 1) {
            setNextBackShouldCloseSheet(false)
            setBottomSheetToMinHeight()
            return true
        }

        // If the sheet is minimized, delete any history and the app will close on next back button press
        if (isBottomSheetMinimized()) {
            setLayout(DEFAULT_LAYOUT)
            setExplorerView(DEFAULT_EXPLORER_VIEW)
            setProductID(null)
            setHistoryPopped(true)
            return true
        }

        history.pop()
        const previousView = history[history.length - 1]

        // If the previous view was a forecast, we should zoom into the polygon bounds
        if (previousView && previousView.view && previousView.id && previousView.view === FORECAST) {
            zoomAndSetActive(map, areas, previousView.id, setMap, activeRegion, setActiveRegion)
        }

        setLayout(previousView?.layout || DEFAULT_LAYOUT)
        setExplorerView(previousView?.view || DEFAULT_EXPLORER_VIEW)
        setProductID(previousView?.id || null)
        setHistory(history || [DEFAULT_HISTORY_VALUE])
        setHistoryPopped(true)

        return true
    })

    // Used in the Account view to navigate to the MIN Edit page
    const setMINLayoutWithMIN = reportData => {
        setEditingMIN(reportData)
        setLayout(MIN_LAYOUT)
        setExplorerView(MIN_LAYOUT)

        setHistory([DEFAULT_HISTORY_VALUE])
    }

    // Used in the MIN Submit page to navigate to the recently submitted MIN
    const setLayoutWithProduct = (layout = DEFAULT_LAYOUT, view = DEFAULT_EXPLORER_VIEW, id = null) => {
        setLayout(layout)
        setExplorerView(view)
        setProductID(id)

        setHistory([DEFAULT_HISTORY_VALUE])
    }

    // Scroll to top of bottom sheet when any kind of view changes
    useEffect(() => {
        const sheetElement = document.querySelector('[data-rsbs-scroll="true"]')
        if (sheetElement) {
            const { y } = sheetElement.getBoundingClientRect()
            sheetElement.scrollTo(y, 0)
        }
    }, [layout, explorerView, productID])

    return (
        <>
            <ProductTour
                updateProduct={updateProduct}
                updateView={updateView}
                handleSetLayout={handleSetLayout}
                forceShowTour={forceShowTour}
                setForceShowTour={setForceShowTour}
            />
            {updateAvailable && <UpdateOverlay />}
            {layout === EXPLORER_LAYOUT && (
                <ExplorerLayout
                    productID={productID}
                    setProduct={updateProduct}
                    view={explorerView}
                    layout={layout}
                    setLayout={handleSetLayout}
                >
                    {explorerView === EXPLORER_VIEW && <Explorer setView={updateView} />}
                    {explorerView === 'Account' && (
                        <Account
                            setMINLayoutWithMIN={setMINLayoutWithMIN}
                            updateView={updateView}
                            setForceShowTour={setForceShowTour}
                        />
                    )}
                    {explorerView === MOUNTAIN_INFORMATION_NETWORK && (
                        <MINListView productID={productID} setProduct={setProductID} />
                    )}
                    {explorerView === MOUNTAIN_CONDITIONS_REPORT && (
                        <MCRListView productID={productID} setProduct={setProductID} />
                    )}
                    {explorerView === FORECAST && <Forecast id={productID} />}
                    {explorerView === WEATHER_STATION && (
                        <WeatherStationListView productID={productID} setProduct={setProductID} />
                    )}
                    {explorerView === ACCIDENT && <AccidentListView productID={productID} setProduct={setProductID} />}
                    {explorerView === ICE_CLIMB && <IceClimbListView productID={productID} setProduct={setProductID} />}
                    {explorerView === SPAW && <SPAWComponent />}
                </ExplorerLayout>
            )}
            {layout === MIN_LAYOUT && (
                <MINDrawerLayout
                    layout={layout}
                    setLayout={handleSetLayout}
                    setLayoutWithProduct={setLayoutWithProduct}
                />
            )}
        </>
    )
}

// utils
const addCanGoBack = router => {
    router.push(
        {
            pathname: '/',
            query: { canGoBack: true },
        },
        null,
        { shallow: true }
    )
}

const lastViewSameAsCurrent = (history, layout, explorerView, productID) => {
    const lastView = history[history.length - 1]
    return lastView && lastView.layout === layout && lastView.view === explorerView && lastView.id === productID
}

const zoomAndSetActive = (map, areas, id, setMap, activeRegion, setActiveRegion) => {
    // Zooms to the polygon bounds
    const area = areas?.features?.find(area => area.properties.id === id)

    if (!area || !area.bbox) return

    map.fitBounds(area.bbox, {
        padding: {
            top: 50,
            bottom: window.innerHeight * 0.5,
            left: 50,
            right: 50,
        },
    })

    // Sets the active region (need to set it in the map as well as the state)
    if (activeRegion !== null) {
        map.setFeatureState(
            {
                source: FORECAST,
                id: activeRegion,
            },
            { hover: false }
        )
    }
    setActiveRegion(id)
    map.setFeatureState(
        {
            source: FORECAST,
            id,
        },
        { hover: true }
    )
    setMap(map)
}
