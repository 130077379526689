import { useIntl } from 'react-intl'

import { IoInformationCircleOutline } from 'react-icons/io5'

import { Confidence } from './Confidence'
import { useReport } from './Context'
import { DetailSet } from './DetailSet'
import { Summary } from './Summary'
import { TravelAndTerrainAdvice } from './TravelAndTerrainAdvice'
import { DangerRatings } from './danger'
import { ProblemSet } from './problem'
import { useOpenDangerRatingModal } from 'stores/DangerRatingModalStore'

import styles from './Forecast.module.css'

export const TabSet = () => {
    const intl = useIntl()

    const { confidence } = useReport()
    const { rating } = confidence
    const openDangerRatingModal = useOpenDangerRatingModal()

    return (
        <>
            <Summary
                title={intl.formatMessage({
                    defaultMessage: 'Danger Ratings',
                    description: 'forecast header',
                })}
                titleKey="dangerRatings"
                rightButton={
                    <button
                        onClick={openDangerRatingModal}
                        className={styles.InfoButton}
                        aria-label="info on danger ratings"
                    >
                        <IoInformationCircleOutline />
                    </button>
                }
            >
                <DangerRatings />
            </Summary>
            <Summary
                title={intl.formatMessage({
                    defaultMessage: 'Terrain and Travel Advice',
                    description: 'forecast header',
                })}
                titleKey="terrainAndTravelAdvice"
            >
                <TravelAndTerrainAdvice />
            </Summary>
            <Summary
                title={intl.formatMessage({
                    defaultMessage: 'Problems',
                    description: 'forecast header',
                })}
                titleKey="problems"
            >
                <ProblemSet />
            </Summary>
            <DetailSet />
            {confidence && (
                <Summary title={intl.formatMessage({ defaultMessage: 'Confidence' })} titleKey="confidence">
                    <h4 style={style.confidence}>{rating?.display}</h4>
                    <Confidence />
                </Summary>
            )}
        </>
    )
}

const style = {
    confidence: {
        marginTop: '0',
        marginBottom: '0.5em',
    },
}
