import create from 'zustand'

type State = {
    sponsors: object[]
    highlight: object
    forecastDocuments: {
        en: object[]
        fr: object[]
    }
}

type Action = {
    setSponsors: (sponsors: State['sponsors']) => void
    setHighlight: (highlight: State['highlight']) => void
    setForecastDocuments: (forecastDocuments: State['forecastDocuments']) => void
}

const PrismicDataStore = create<State & Action>()(set => {
    return {
        sponsors: [],
        highlight: {},
        forecastDocuments: {
            en: [],
            fr: [],
        },
        setSponsors(sponsors: State['sponsors']) {
            set({ sponsors })
        },
        setForecastDocuments(forecastDocuments: State['forecastDocuments']) {
            set({ forecastDocuments })
        },
        setHighlight(highlight: State['highlight']) {
            set({ highlight })
        },
    }
})

export const useSponsors = () => PrismicDataStore(state => state.sponsors)
export const useSetSponsors = () => PrismicDataStore(state => state.setSponsors)
export const useHighlight = () => PrismicDataStore(state => state.highlight)
export const useSetHighlight = () => PrismicDataStore(state => state.setHighlight)
export const useForecastDocuments = () => PrismicDataStore(state => state.forecastDocuments)
export const useSetForecastDocuments = () => PrismicDataStore(state => state.setForecastDocuments)
