import { forType, OneLiner } from 'components/alert'
import { useVisibility } from 'hooks/storage/session'
import { Link, StructuredText } from 'prismic/components/base'
import { useHighlight } from 'stores/PrismicDataStore'

const ACCEPTABLE_LAYOUTS = ['both', 'pwa']

export const Highlight = () => {
    const [visible, hide] = useVisibility('highlight')

    const highlight = useHighlight()

    if (!visible || !highlight.data) return null

    const { link, description, style, layout } = highlight.data
    const forPWA = layout ? ACCEPTABLE_LAYOUTS.includes(layout) : true

    if (!forPWA) return null

    const Alert = forType(style)
    const children = (
        <OneLiner>
            <StructuredText value={description} />
        </OneLiner>
    )

    return <Alert onDismiss={hide}>{link ? <Link {...link}>{children}</Link> : children}</Alert>
}
