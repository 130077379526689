import { useForecasts } from './forecasts'
import { useAccidents } from './accidents'
import { useWeatherStations } from './weather'
import { useMountainConditionsReports } from './MCR'
import { useMINSubmissions } from './MIN'
import { useSpawArea } from './SPAW'

export const useDataLoading = () => {
    // Important: high-level data fetching hooks should be invoked here to avoid "undefined" errors (e.g. side effects like generating geojson feature collections)
    const { forecasts, areas, metadata } = useForecasts()
    const { accidents } = useAccidents()
    const { stations } = useWeatherStations()
    const { reports } = useMountainConditionsReports()
    const { submissions } = useMINSubmissions()
    const { spawArea } = useSpawArea()

    const isLoading =
        !forecasts || !areas || !metadata || !accidents || !stations || !reports || !submissions || !spawArea

    return {
        isLoading,
    }
}
